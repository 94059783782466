<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="invoice-preview-wrapper">
          <b-alert variant="danger" v-if="!order">
            <h4 class="alert-heading">Ошибка получения данных</h4>
            <div class="alert-body">
              Не найдено заказа с таким ID. Проверьте
              <router-link class="alert-link" :to="{ name: 'order-list'}">Список заказов</router-link>
              чтобы увидеть другие заказы.
            </div>
          </b-alert>
          <div v-if="order" class="row invoice-preview">
            <div class="col-12 col-xl-9 col-md-8">
              <div class="card invoice-preview-card">
                <div class="card-body invoice-padding pb-0">
                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                    <div>
                      <p class="card-text mb-25"><b>ТТН:</b><br> {{ order.ttn }}</p>
                      <p class="card-text mb-25" v-if="order.delivery_address">Адрес Доставки:</p>
                      <div v-if="order.delivery_address">
                        <p class="card-text mb-25">
                          <b>Улица:</b> {{ order.delivery_address.street }} {{ order.delivery_address.building }}
                          {{ order.delivery_address.flat }}
                        </p>
                        <p class="card-text mb-25"><b>Город:</b> {{ order.delivery_address.city }}</p>
                        <p class="card-text mb-25"><b>Индекс:</b> {{ order.delivery_address.zip }}</p>
                        <p class="card-text mb-25">
                          <b>Получатель:</b> {{ order.delivery_address.name }} {{ order.delivery_address.surname }}
                        </p>
                        <p class="card-text mb-25"><b>Контактный номер:</b> {{ order.delivery_address.phone }}</p>
                        <p class="card-text mb-25"><b>Транспортная компания:</b>
                          {{ order.delivery_address.logistics_company }}</p>
                        <p class="card-text mb-25"><b>Доп информация:</b><br> {{ order.delivery_address.comments }}</p>
                      </div>
                    </div>
                    <div class="mt-md-0 mt-2">
                      <h4 class="invoice-title mb-1">
                        Заказ <span class="invoice-number">#{{ $route.params.id }}</span>
                      </h4>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Дата заказа:</p>
                        <p class="invoice-date">{{ $formatters.formattedDate(order.created_at) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="invoice-spacing" v-if="$can('read', 'users')">
                <div class="card-body invoice-padding pt-0">
                  <div class="row invoice-spacing">
                    <div class="col-12 col-xl-6 p-0">
                      <h6 class="mb-2">Покупатель:</h6>
                      <div class="row">
                        <div class="col-12" v-if="order.user && $can('read', 'users')">
                          <h6 class="mb-25">{{ order.user.name }} {{ order.user.surname }}</h6>
                          <p class="card-text mb-25">{{ order.user.company_name }}</p>
                          <p class="card-text mb-25">{{ order.user.phone }}</p>
                          <p class="card-text mb-0">{{ order.user.email }}</p>
                        </div>
                        <div v-else class="col-12">
                          <h3 class="mb-25">{{ order.user.id }}</h3>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <hr/>
                <div class="card-body invoice-padding pt-0">
                  <h5>Комментарии к заказу</h5>
                  <div class="row">
                    <div class="col-12">
                      {{ order.comments }}
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table b-table">
                    <thead>
                    <tr>
                      <th>Товар</th>
                      <th>Фото</th>
                      <th>Размер</th>
                      <th>Состояние</th>
                      <th>К-во</th>
                      <th>Вес (кг)</th>
                      <th v-if="$can('read', 'payments')">Цена</th>
                      <th v-if="$can('read', 'payments')">Прайс</th>
                      <th>Дата</th>
                      <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(item, index) in order.index_items">
                      <tr :key="`storage-${index})`">
                        <td colspan="8">Коробка {{ index }}</td>
                      </tr>
                      <tr v-for="object in item" :key="object.item.id" class="grouped-row">
                        <td>
                          <router-link
                              class="text-body"
                              :to="{name: 'product-details', params: { id: object.item.product_specification.product.id}}"
                          >
                            {{ object.item.product_specification.product.name }}
                            {{ object.item.product_specification.product.brand_name }}
                          </router-link>
                          <br/>
                          <small>Артикул: {{ object.item.product_specification.product.sku }}</small> <br/>
                        </td>
                        <td class="p-0">
                          <img
                              @click="showImage(`/img/product/${object.item.product_specification.product.big_main_image}`)"
                              v-if="object.item.product_specification.product.small_main_image"
                              :src="`/img/product/${object.item.product_specification.product.small_main_image}`"
                              class="product-img"
                              alt=""
                          >
                        </td>
                        <td class="text-uppercase">{{ object.item.product_specification.size_name }}</td>
                        <td>{{ object.item.product_specification.condition_name }}</td>
                        <td>{{ object.qty }}</td>
                        <td>{{ object.item.product_specification.weight / 1000 }}</td>
                        <td v-if="$can('read', 'payments')">
                          {{ object.item.cost }} <span class="currency">{{ object.item.currency.name }}</span>
                        </td>
                        <td v-if="$can('read', 'payments')" class="text-nowrap text-center">
                          {{ object.item.user_price }} <span class="currency">UAH</span><br>
                          <span class="font-small-3 text-danger">(Скидка: {{ object.item.user_discount_percent }}%)</span>
                        </td>
                        <td>{{ object.item.created_at | formattedDate }}</td>
                        <td>
                          <button class="btn btn-primary" @click="printBarcode(object.item.product_specification.id)">
                            Штрихкод
                          </button>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
                <hr class="invoice-spacing">
                <div class="card-body invoice-padding pb-0">
                  <div class="row">
                    <div class="col-12 col-md-6 mt-md-0 mt-3 order-2 order-md-1">
                      <div class="card-text mb-0" v-if="$can('read', 'payments')">
                        <span class="font-weight-bold">Менеджер:</span>
                        <span class="ml-75">Анастасия</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 order-1 order-md-2 mt-md-6 d-flex justify-content-end mb-2">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Кол-во:</p>
                          <p class="invoice-total-amount">{{ order.total_qty }}</p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Вес (кг):</p>
                          <p class="invoice-total-amount">{{ order.total_weight / 1000 }}</p>
                        </div>
                        <hr class="my-50" v-if="$can('read', 'payments')">
                        <div class="invoice-total-item" v-if="$can('read', 'payments')">
                          <p class="invoice-total-title">Сумма:</p>
                          <p class="invoice-total-amount">
                            {{ order.total_amount }} <span
                              class="currency">{{ order.items.length > 0 && order.items[0].currency.name }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <h5 class="mt-2 mb-2" v-if="$can('read', 'payments')">Партийный учет</h5>
                <div class="position-relative table-responsive" v-if="$can('read', 'payments')">
                  <table class="table b-table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Артикул</th>
                        <th>Количество</th>
                        <th>Номер прихода</th>
                        <th>Себестоимость</th>
                        <th>Себестоимость (грн)</th>
                        <th>Продажа</th>
                        <th>Дата прихода</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="batch in batches" :key="batch.id+'_batch'">
                        <td>{{ batch.id }}</td>
                        <td>{{ batch.product_specification.product.sku }}</td>
                        <td>{{ batch.qty }}</td>
                        <td v-if="$can('view', 'arrivals')">
                          <router-link :to="{name: 'arrival-details', params: { id: batch.batch.arrival_id }}">
                            {{ batch.batch.arrival_id }}
                          </router-link>
                        </td>
                        <td v-else>{{ batch.batch.arrival_id }}</td>
                        <td>{{ batch.batch.cost }} <span class="currency">{{ batch.batch.currency.name }}</span></td>
                        <td>{{ batch.uah_cost }} <span class="currency">UAH</span></td>
                        <td>{{ batch.transaction.cost }} <span class="currency">{{ batch.transaction.currency.name }}</span></td>
                        <td>{{ batch.batch.updated_at | formattedDate }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 invoice-actions">
              <div class="card">
                <div class="card-body">
                  <router-link
                      class="btn mb-75 btn-primary btn-block"
                      v-if="$can('manage', 'orders')"
                      :to="{ name: 'order-edit', params: { id: $route.params.id } }"
                  >
                    Редактировать
                  </router-link>
                  <button
                      v-if="$can('manage', 'order')"
                      type="button"
                      class="btn btn-danger btn-block"
                      @click="deleteOrder"
                  >
                    Удалить
                  </button>
                  <button type="button" class="btn btn-outline-secondary btn-block mb-75" @click="printInvoice">
                    Распечатать
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ProductBigImageModal :image="selectedImage"/>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ProductBigImageModal from '@/views/products/ProductBigImageModal.vue';

export default {
  name: 'OrderDetails',
  directives: { Ripple },
  components: { BAlert, ProductBigImageModal },
  data() {
    return {
      order: null,
      selectedImage: null,
      batches: null,
    };
  },
  async mounted() {
    await this.getOrderInfo();

    if (this.$can('read', 'payments')) {
      await this.getBatches();
    }
  },
  methods: {
    async getOrderInfo() {
      this.order = (await this.$api.orders.get(this.$route.params.id)).data;
    },
    async getBatches() {
      this.batches = (await this.$api.batches.order.details(this.$route.params.id)).data;
    },
    showImage(image) {
      this.selectedImage = image;
      this.$bvModal.show('modal-product-big-image');
    },
    printInvoice() {
      window.print();
    },
    async printBarcode(id) {
      const content = (await this.$api.productSpecifications.label(id)).data;
      const win = this.openWindow();

      win.document.write(content);
    },
    openWindow() {
      let windowRef = null;
      windowRef = window.open('/print', '_blank');
      if (!windowRef.opener) {
        windowRef.opener = this;
      }
      windowRef.focus();

      return windowRef;
    },
    async deleteOrder() {
      this.$swal({
        title: 'Вы уверены?',
        text: 'Вы не сможете это вернуть назад!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, подтверждаю!',
        cancelButtonText: 'Нет, отмена!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$api.orders.delete(this.$route.params.id);
          this.$swal({
            icon: 'success',
            title: 'Удалено!',
            text: 'Заказ был успешно удален.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
          await this.$router.push({ name: 'order-list' });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

span.currency {
  font-size: 10px;
}

.product-img {
  max-height: 80px;
}

.grouped-row {
  background-color: #f9f9f9;
}
</style>
