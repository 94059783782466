<template>
  <b-modal
      id="modal-product-big-image"
      title="Большое фото товара"
      ok-title="Закрыть"
      ok-only
  >
    <div class="row">
      <div class="col-12">
        <VueMagnifier :src="image" :src-large="image" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import VueMagnifier from '@/views/components/VueMagnifier.vue';

export default {
  name: 'ProductBigImageModal',
  props: ['image'],
  components: {
    BModal, VueMagnifier,
  },
};
</script>

<style scoped>

</style>
